import { ThemeProvider, styled } from '@mui/material/styles'
import { Divider, Fab } from '@mui/material'
import PouchDb from "pouchdb-browser";
import theme from "./theme";
import NavBarMaterial from "./components/NavBarMaterial";
import { FeedItemForm, UpdateForm } from './components/SasaviForm'
import React, { useState, useEffect, useCallback } from "react";
import FavoriteIcon from '@mui/icons-material/Favorite';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const Container = styled('div')(() => ({
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  position: 'relative', // Add position relative for positioning FeedItemForm
}))

const Container2 = styled('div')(() => ({
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  position: 'relative', // Add position relative for positioning FeedItemForm
  zIndex: 0, // Set a z-index value to create a stacking context
  paddingBottom: '16rem', // Add padding to the bottom of the container to prevent FeedItemForm from overlapping the last item
}))

const ItemButtons = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row-reverse',
  direction: 'row',
  margin: '0.5rem',
  '& > *:not(:last-child)': {
    marginLeft: '.7rem', // Add margin between the Fabs except for the last one
  },
}))
const Item = ({ item, db, updateFeedData, setItemToUpdate }) => {
  // console.log("item", item)
  // const { url, comment, createdAt } = item
  const {  comment, createdAt } = item
  const date = new Intl.DateTimeFormat('en-GB', { dateStyle: 'full', timeStyle: 'long' }).format(new Date(createdAt))

  const handleDelete = async () => {
    try {
      const currentItem = await db.get(item._id);

      await db.remove(currentItem._id, currentItem._rev); // Remove the item using its id and revision
      console.log("Item deleted:", currentItem._id);
      // Update the feed after deletion
      updateFeedData((prevFeedData) =>
        prevFeedData.filter((feedItem) => feedItem._id !== currentItem._id)
      );
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  const handleLike = async () => {
    try {
      console.log("handle like")
      // Toggle the 'liked' property in the item object
      const currentItem = await db.get(item._id);
      const updatedItem = { ...currentItem, liked: !currentItem.liked }; await db.put(updatedItem); // Update the item in the PouchDB database
      console.log(`Item ${updatedItem._id} like '${!updatedItem.liked}' status toggled`);

      // Update the feed data after the like status is toggled
      updateFeedData((prevFeedData) =>
        prevFeedData.map((feedItem) =>
          feedItem._id === updatedItem._id ? updatedItem : feedItem
        )
      );
    } catch (error) {
      console.error("Error toggling like status:", error);
    }
  };

  const handleUpdate = async () => {
    try {
      // Toggle the 'liked' property in the item object
      // console.log("handle update", item)
      const currentItem = await db.get(item._id);
      setItemToUpdate(currentItem)

    } catch (error) {
      console.error("Error toggling like status:", error);
    }
  }

  return <>
    <Divider />
    <UnderLayer>
      <b>{date}</b>
    </UnderLayer>
    <p dangerouslySetInnerHTML={{ __html: comment.replace(/\n/g, "<br />") }} />
    <ItemButtons>
      {/* // They'll be displayed in reverse order */}

      {/* <Fab color="primary" aria-label="delete"> */}
      <Fab color="primary" aria-label="delete" onClick={handleDelete}>
        <DeleteIcon />
      </Fab>

      <Fab color="secondary" aria-label="edit" onClick={handleUpdate}>
        <EditIcon />
      </Fab>
      <Fab
        // disabled={item.liked} // Disable the Fab if the item is already liked
        color={item.liked ? "primary" : "default"} // Change color if liked
        aria-label="like"
        onClick={handleLike}
      >
        <FavoriteIcon />
      </Fab>

    </ItemButtons>

  </>
}

const FeedItemFormStyle = styled('div')(() => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: '100%',
  zIndex: 1000,
  backgroundColor: 'white',
  // Add other styling properties as needed
}));

const UnderLayer = styled('div')(() => ({
  marginBottom: '10px',
  zIndex: 0,
}))

const db = new PouchDb('sasavi-db');

const App = () => {

  const [feedData, updateFeedData] = useState();
  const [itemToUpdate, setItemToUpdate] = useState();
  const [isFilterByLiked, setFilterLiked] = useState(false);
  
  const fetchFeed = useCallback(async (isFilterByLiked) => {
    try {
      console.log("fetch feed called 1", isFilterByLiked);
      const options = isFilterByLiked ? {liked: true} : {}
      console.log("fetch feed called 2", options);
      const something = await db.allDocs({ include_docs: true });
      console.log("feed data", something.rows);

      let data = await Promise.all(
        something.rows.map(async (r) => {
          const foo = await db.get(r.id);
          return foo;
        })
      );

      if (isFilterByLiked) {
        data = data.filter((item) => item.liked === true)
      }

      const sortedData = data.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      console.log("updating the feed")
      updateFeedData(sortedData)
    } catch (error) {
      console.error("Error fetching feed:", error);
    }
  }, []);

  useEffect(() => {
    fetchFeed(isFilterByLiked)
  }, [fetchFeed, isFilterByLiked]);

  return (
    <ThemeProvider theme={theme}>
      <Container id="app">
        <Container2>
          {feedData && feedData?.map((r) => {
            return <Item item={r} key={r._id} db={db} updateFeedData={updateFeedData} setItemToUpdate={setItemToUpdate}></Item>
          })}
        </Container2>
        <FeedItemFormStyle>
          <Divider />
          <NavBarMaterial isFilterByLiked={isFilterByLiked} setFilterLiked={setFilterLiked}/>
          { itemToUpdate && <UpdateForm fetchFeed={fetchFeed} setItemToUpdate={setItemToUpdate} db={db} item={itemToUpdate} ></UpdateForm>}
          { !itemToUpdate && <FeedItemForm fetchFeed={fetchFeed} db={db}  ></FeedItemForm>}
        </FeedItemFormStyle>
      </Container>
    </ThemeProvider>
  );
};

export default App;
