import { createTheme } from '@mui/material/styles'

// export const primaryColour = '#dd944b'
// export const primaryDarkColour = '#c27325'
// export const secondaryColour = '#dd4b4b'
// export const secondaryDarkColour = '#ad2121'

export const secondaryColour = '#dd944b'
export const secondaryDarkColour = '#c27325'
export const primaryColour = '#dd4b4b'
export const primaryDarkColour = '#ad2121'

const theme = createTheme({
  palette: {
    primary: {
      main: primaryColour,
      dark: primaryDarkColour,
    },
    secondary: {
      main: secondaryColour,
      dark: secondaryDarkColour,
    },
    error: {
      main: '#f1360e'
    },
    // background: {
    //   default: '#f0f0f0'
    // },
    // tableHead: {
    //   main: '#404040'
    // },
    // circleColors: {
    //   main: primaryRed,
    //   dark: '#76cc54',
    //   icon: lightGrey,
    //   border: '#fff'
    // },
    // graph: {
    //   won: '#76cc54',
    //   lost: '#da301b',
    //   background: '#fff'
    // }
  },
  typography: {
    h1: {
      fontSize: '2rem',
      color: secondaryColour
    },
    // h2: {
    //   fontSize: '1.5rem',
    //   color: primaryRed
    // },
    // h3: {
    //   fontSize: '1.3rem',
    //   color: lightGrey
    // },
    // h4: {
    //   fontSize: '1.1rem',
    //   color: '#000',
    //   textTransform: 'uppercase',
    //   marginBottom: '0.8rem'
    // }
  },
  components: {
    // MuiTab: {
    //   styleOverrides: {
    //     root: {
    //       flexGrow: 1
    //       // maxWidth: "100%"
    //     }
    //   }
    // },
    // MuiTabs: {
    //   styleOverrides: {
    //     root: {
    //       // borderBottom: "2px solid grey"
    //     }
    //   }
    // },
    // MuiListItemIcon: {
    //   styleOverrides: {
    //     root: {
    //       minWidth: '2.2rem'
    //     }
    //   }
    // }
  }
})

export default theme
