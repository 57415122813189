// import { Button, styled, TextField, Box, Fab } from '@mui/material';
import { Button, styled, TextField } from '@mui/material';
import { useFormik } from 'formik'

const MyForm = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '1rem'
}))

const MyTextField = styled(TextField)(() => ({
  marginBottom: '10px'
}))

// const Hiddable = styled('div')(({ isVisible }) => ({
//   display: isVisible ? 'block' : 'none', // Set display property based on isVisible
// }))

export const FeedItemForm = ({ fetchFeed, db }) => {
  const formik = useFormik({
    initialValues: {
      url: '',
      comment: '',
      createdAt: null
    },
    onSubmit: async (values, { resetForm }) => {
      if (!values.url && !values.comment) {
        await fetchFeed()
        return
      }
      await submitForm({ ...values, createdAt: new Date() })
      await fetchFeed()
      resetForm()
    },
  });

  const submitForm = async (data) => {
    db.post(data)
  }

  return (
    <>
      <MyForm onSubmit={formik.handleSubmit}>
        <MyTextField
          multiline
          rows={'4'}
          id="comment"
          label="comment"
          variant="outlined"
          onChange={formik.handleChange}
          value={formik.values.comment} />
        {/* <MyTextField id="url" label="url" variant="outlined" type='url' onChange={formik.handleChange} value={formik.values.url} /> */}
        <Button type="submit" variant="contained" value="sasavi it!">sasavi it!</Button>
      </MyForm>
    </>

  );
}

export const UpdateForm = ({ fetchFeed, setItemToUpdate, db, item }) => {
  const { _id: itemId, url: itemUrl, comment: itemComment, createdAt: itemCreatedAt } = item || {}
  const formik = useFormik(
    {
    initialValues: {
      _id: itemId || '',
      url: itemUrl || '',
      comment: itemComment || '',
      createdAt: itemCreatedAt || null,
      updatedAt: null
    },
    onSubmit: async (values, { resetForm }) => {
      setItemToUpdate(null) // this will hide the update form, and display create form
      console.log("update submit", values)
      if (!values.url && !values.comment) {
        return
      }

      await updateItem({ ...values, updatedAt: new Date() })
      resetForm()

      await fetchFeed()
    },
  });

  const updateItem = async (data) => {
    console.log("update item", data)
    const item = await db.get(data._id)
    await db.put({ ...item, ...data })
    console.log("done")
  }

  return (
    <>
      <MyForm onSubmit={formik.handleSubmit}>
        <MyTextField
          multiline
          rows={'4'}
          id="comment"
          label="comment"
          variant="outlined"
          onChange={formik.handleChange}
          value={formik.values.comment} />
        <Button type="submit" variant="contained" value="updated it!">update it!</Button>
      </MyForm>
    </>
  );
}